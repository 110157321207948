@tailwind base;
@tailwind components;
@tailwind utilities;
/* For WebKit browsers (Chrome, Safari) */
/* Styles for the TreeNode */
/* Styles for the TreeNode */
/* Styles for the TreeNode */
.tree-node {
    position: relative;
    margin-bottom: 40px;
  }
  
  .tree-node img {
    border-radius: 50%;
    border: 2px solid #ddd;
  }
  
  .tree-node .line {
    position: absolute;
    top: 10px;
    left: 50%;
    height: 100%;
    width: 1px;
    background-color: #ccc;
  }
  
  .tree-node .children {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .tree-node .children .tree-node {
    margin: 0 20px;
  }
  